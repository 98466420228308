export default {
  documentTitle: {
    id: 'privacyPage.documentTitle',
    defaultMessage: 'Privacy - Choicely'
  },
  documentDescription: {
    id: 'privacyPage.documentDescription',
    defaultMessage: 'Here you can find full info about privacy'
  },
  privacyPolicy: {
    id: 'privacyPage.privacyPolicy',
    defaultMessage: `
      <h1>PRIVACY POLICY</h1>
      <p>
        <b>New Privacy Policy will become effective on Oct 18, 2024. <a href="https://www.choicely.com/privacy-policy">Click here to read it.</a></b>
      </p>
      <p>Privacy Policy is effective on April 25, 2024.</p>
      <p>
      Our privacy policy explains how information is collected, used and disclosed by Choicely with respect to your access and use of Choicely's services, whether through our website (located at www.choicely.com), Choicely's mobile application or in connection with social networking services (referred to below as “Choicely”).
      </p>
      <h3>WHAT INFORMATION DO WE COLLECT?</h3>
      <p>
      We collect certain information you provide to us when using Choicely, such as when you create an account and profile, send us an email or post information or other content to Choicely. We collect certain personal information, like your name, picture, email address and phone number, as well as certain non-identifying information, like your gender, birthday and zip code. We will also collect the contact information of your friends, if you choose to connect your contacts and address book information with Choicely and your login credentials to your social network accounts, such as Facebook and Twitter, if you choose to connect those accounts with your Choicely account. We may also automatically collect certain information through the use of “cookies”. These are small files that your browser places on your computer. We may use both session cookies and persistent cookies to better understand how you interact with our services, to monitor aggregate usage by our users and web traffic routing on our services, and to improve our services. Most Internet browsers automatically accept cookies. You can instruct your browser, by editing its options, to stop accepting cookies or to prompt you before accepting a cookie from the websites you visit.
      </p>
      <h3>HOW DO WE USE THE INFORMATION WE COLLECT?</h3>
      <p>We use the information collected through Choicely for the purposes described below:</p>
      <ul class="list-disc">
        <li>to provide our services or information you request, and to process and complete any transactions;</li>
        <li>to respond to your emails, submissions, questions, comments, requests, and complaints and provide customer service;</li>
        <li>to monitor and analyze usage and trends, and to personalize and improve Choicely and your experiences when you use Choicely, such as providing content or features that match your profile or interests (including ads), and to increase the functionality and user friendliness of our services;</li>
        <li>to send you confirmations, updates, security alerts, and support and administrative messages and otherwise facilitate your use of, and our administration and operation of, our services;</li>
        <li>to allow you to add your location to your posts;</li>
        <li>to find and connect with your friends (when instructed by you);</li>
        <li>to let your friends know about your moments on Choicely</li>
        <li>to let your friends know it’s your birthday (if you don’t disable this feature); and</li>
        <li>for any other purpose for which the information was collected.</li>
      </ul>
      <h3>WHAT INFORMATION DO WE SHARE WITH THIRD PARTIES?</h3>
      <p>We will not share the personal information we have collected from you, except as described below:</p>
      <ul class="list-disc">
        <li>with your friends on Choicely with whom you want to share your moments on Choicely, and according to the preferences set in your account;</li>
        <li>with certain social networking services, if you allow such sharing through our services;</li>
        <li>with service providers who are working with us in connection with the operation of our site or our services (these service providers have access to your personal information only to perform services on our behalf and are obligated not to disclose it or use it for any other purposes);</li>
        <li>we may share aggregated information and non-identifiable information with third parties for industry analysis, demographic profiling and other similar purposes;</li>
        <li>we may share information with third parties who are using the Service to support them in analyzing the data we receive through our Service.</li>
        <li>when we believe in good faith that we are lawfully authorized or required to do so or that doing so is reasonably necessary or appropriate to comply with the law or legal processes or respond to lawful requests or legal authorities, including responding to lawful subpoenas, warrants or court orders;</li>
        <li>to enforce or apply our privacy policy, our terms of use or our other policies or agreements; and</li>
        <li>in connection with, or during negotiations of, any merger, sale of company assets, financing or acquisition, or in any other situation where personal information may be disclosed or transferred as one of our business assets.</li>
      </ul>
      <p>
        We are not responsible for the actions of service providers or other third parties, nor are we responsible for any additional information you provide directly to any third parties.
      </p>
      <p>
        Some of these third parties, such as analytics providers, may collect information about your online activities over time and across different websites or online services when you use our services.
      </p>
      <h3>LINKS TO OTHER SITES</h3>
      <p>
        Choicely may contain links to other sites. Any information you provide on those sites is provided directly to the owner of that site and is subject to that party’s privacy policy. Our privacy policy does not apply to such sites, and we are not responsible for the content or privacy and security practices and policies of those sites.
      </p>
      <h3>WHAT STEPS DO WE TAKE TO PROTECT YOUR INFORMATION ONLINE?</h3>
      <p>
        We take reasonable measures to protect your personal information in an effort to prevent loss, misuse and unauthorized access, disclosure, alteration and destruction. Please be aware, however, that despite our efforts, no security measures are perfect or impenetrable and no method of data transmission can be guaranteed against any interception or other type of misuse.
      </p>
      <h3>ACCESSING AND MODIFYING YOUR INFORMATION</h3>
      <p>
        If you have an account, you can access and modify the personal information you provided us that is associated with your account. You may “opt out” of receiving marketing or promotional emails from us by changing your account preferences or by following the instructions in those emails.
      </p>
      <p>
        If you opt out of these messages, you will still receive emails from us, about your account, our services and other pertinent information related to our services. These communications are considered part of the service and your account, which you cannot opt-out from receiving. You can deactivate your account by selecting the “deactivate” link. Deactivating your account lets you remove your profile without permanently deleting it.
      </p>
      <p>
        When you deactivate your account, other users will no longer see your profile or information. Also, If you want to delete your information and your account, please contact us with a request that we delete your account and information. We will take steps to delete your information as soon as is practicable, but some information may remain in archived/backup copies for our records and as otherwise required by law.
      </p>
      <h3>INTERNATIONAL USERS</h3>
      <p>
        If you are located outside Finland and choose to provide information to us, we may transfer your information to Finland and process it there (or any other country where we operate). By using Choicely, you consent to the collection, transfer, use, storage and disclosure of your information as described in this privacy policy.
      </p>
      <h3>OUR POLICY TOWARD CHILDREN</h3>
      <p>
        Our service is not directed to children under 13 and we do not knowingly collect personal information from children under 13. If we learn that we have collected personal information of a child under 13, we will take steps to delete such information from our files as soon as possible.
      </p>
      <h3>DEVELOPER DATA RETENTION AND DELETION POLICY</h3>
      <p>This policy outlines how user data collected by our applications is retained and deleted. We are committed to respecting user privacy and providing clear guidelines on data management.</p>
      <h2>1. User-Initiated Deletion</h2>
      <ul class="list-disc">
        <li>Users have the right to delete their accounts and associated data at any time.</li>
        <li>This can be done through an in-app functionality or by contacting our support team.</li>
        <li>Upon deletion request:
          <ul class="list-disc">
            <li>The user's account will be deactivated immediately.</li>
            <li>All private user information will be irreversibly deleted within 90 days. This includes:
              <ul class="list-disc">
                <li>User account details (name, email, etc.)</li>
                <li>User-generated content (documents, photos, etc.)</li>
                <li>App activity logs</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <h2>2. Inactive Account Deletion</h2>
      <ul class="list-disc">
        <li>We identify inactive accounts based on user login activity.</li>
        <li>An account is considered inactive if there is no login activity for a period of three (3) years.</li>
        <li>For inactive accounts:
          <ul class="list-disc">
            <li>The account will be flagged for deletion.</li>
            <li>An automated email notification will be sent to the user's registered email (if available) warning about impending deletion due to inactivity. This notification will include instructions to regain access and prevent deletion if the user wishes to keep their account.</li>
            <li>If no response is received within a specified timeframe (e.g., 30 days) after the notification, the account will be permanently deleted along with all associated data. The deletion timeframe will mirror user-initiated deletion (90 days).</li>
          </ul>
        </li>
      </ul>
      <h2>3. Data Retention Exceptions</h2>
      <ul class="list-disc">
        <li>Legal Requirements: Certain user data may be subject to legal or regulatory requirements and will be retained for the mandated period, even after account deletion. This data will be stored securely and in accordance with all applicable laws.</li>
        <li>Anonymized Data: After the deletion period, some user data may be anonymized and aggregated for analytical purposes. This anonymized data will not be linked to any individual user and will be used solely for improving the application.</li>
      </ul>
      <h2>4. Developer Responsibilities</h2>
      <ul class="list-disc">
        <li>We are committed to implementing and maintaining technical and organizational measures to ensure secure data storage and deletion.</li>
        <li>We will update this policy periodically to reflect any changes in our data practices and comply with evolving regulations. Updates will be communicated through the application or via email notifications.</li>
      </ul>
      <h2>5. User's Responsibilities</h2>
      <ul class="list-disc">
        <li>Users are responsible for reviewing this policy and understanding how their data is managed.</li>
        <li>Users are encouraged to keep their contact information up-to-date to receive relevant notifications.</li>
      </ul>
      <h2>6. Additional Considerations</h2>
      <ul class="list-disc">
        <li>This policy applies to data collected directly by our applications. User data shared with third-party services may be subject to their own data retention policies. Users are advised to review those policies for further information.</li>
      </ul>

      <h3>CHANGES</h3>
      <p>
        Any information that is collected is subject to the privacy policy in effect at the time such information is collected. We may, however, modify and revise our privacy policy from time-to-time. If we make any material changes to this policy, we will notify you of such changes by posting them on Choicely or by sending you an email or other notification, and we will indicate when such changes will become effective. By continuing to access or use Choicely after those changes become effective, you are agreeing to be bound by the revised policy.
      </p>
      <h3>QUESTIONS?</h3>
      <p>
        Please contact us via <a href="mailto:visa@choicely.com" class="link">visa@choicely.com</a> if you have any questions about our privacy policy.
      </p>
    `
  }
}
