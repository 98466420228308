import React, { Component } from 'react'
import DocumentMeta from 'react-document-meta'
import { Link } from 'gatsby'

import FormattedHTMLMessage from '@/formatted-html-message'
import Layout from '@/layout'
import Container from '@/container'
import TermsPrivacyWrapper from '@/terms-privacy-wrapper'
import ContactForm from '@/contact-form'

import intlMessages from 'i18n/privacyMessages'

class PrivacyPage extends Component {
  render() {
    return (
      <Layout>
        <DocumentMeta
          title={intlMessages.documentTitle.defaultMessage}
          extend
        />
        <Container>
          <TermsPrivacyWrapper>
            <FormattedHTMLMessage {...intlMessages.privacyPolicy} />
            <ContactForm />
            <Link to="/rekisteriseloste" className="link">
              Rekisteriseloste (in Finnish)
            </Link>
          </TermsPrivacyWrapper>
        </Container>
      </Layout>
    )
  }
}

export default PrivacyPage
